<template>
  <router-link to="/" class="flex justify-center items-center">
          <img src="@/assets/logo.png" class="w-[40px] mr-2" alt="" />
          <span class="font-bold whitespace-nowrap flex flex-col justify-start items-end">
            <span>IA xPress</span>
            <span class="font-normal text-[11px]">v{{ version }}</span>
          </span>
        </router-link>
</template>

<script>
import { version } from "../../package";
export default {
  data() {
    return {
      version
    }
  }
}
</script>

<style>

</style>